// Generated by Framer (999bb5e)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {khyIvQtxG: {hover: true}};

const serializationHash = "framer-tEOYy"

const variantClassNames = {khyIvQtxG: "framer-v-sem89y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "khyIvQtxG", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-sem89y", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"khyIvQtxG"} ref={ref ?? ref1} style={{boxShadow: "none", ...style}} variants={{"khyIvQtxG-hover": {boxShadow: "0px 1px 9px 5px rgb(15, 191, 255)"}}} {...addPropertyOverrides({"khyIvQtxG-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-x90f08"} data-border layoutDependency={layoutDependency} layoutId={"KSjbEEFkw"} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.5)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(15, 191, 255)", borderBottomLeftRadius: 13, borderBottomRightRadius: 13, borderTopLeftRadius: 13, borderTopRightRadius: 13, boxShadow: "inset 0px 1px 9px 5px rgba(255, 255, 255, 0.3)"}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO1NGIFBybyBEaXNwbGF5IEJvbGQ=", "--framer-font-family": "\"SF Pro Display Bold\", \"SF Pro Display Bold Placeholder\", \"-apple-system\", \"BlinkMacSystemFont\", sans-serif", "--framer-font-size": "18px", "--framer-letter-spacing": "-0.02em", "--framer-line-height": "1.65em", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Jeg vil have en gratis meditationsvideo</motion.p></React.Fragment>} className={"framer-eb8ers"} fonts={["CUSTOM;SF Pro Display Bold"]} layoutDependency={layoutDependency} layoutId={"KSjbEEFkwQJBsC6fIY"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tEOYy.framer-1imkzm4, .framer-tEOYy .framer-1imkzm4 { display: block; }", ".framer-tEOYy.framer-sem89y { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1680px; }", ".framer-tEOYy .framer-x90f08 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 8px 18px 8px 18px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-tEOYy .framer-eb8ers { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tEOYy.framer-sem89y, .framer-tEOYy .framer-x90f08 { gap: 0px; } .framer-tEOYy.framer-sem89y > *, .framer-tEOYy .framer-x90f08 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-tEOYy.framer-sem89y > :first-child, .framer-tEOYy .framer-x90f08 > :first-child { margin-left: 0px; } .framer-tEOYy.framer-sem89y > :last-child, .framer-tEOYy .framer-x90f08 > :last-child { margin-right: 0px; } }", ".framer-tEOYy[data-border=\"true\"]::after, .framer-tEOYy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 45
 * @framerIntrinsicWidth 1680
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"u2UuYK9aT":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameriCdWXa_F_: React.ComponentType<Props> = withCSS(Component, css, "framer-tEOYy") as typeof Component;
export default FrameriCdWXa_F_;

FrameriCdWXa_F_.displayName = "gratis";

FrameriCdWXa_F_.defaultProps = {height: 45, width: 1680};

addFonts(FrameriCdWXa_F_, [{explicitInter: true, fonts: [{family: "SF Pro Display Bold", source: "custom", url: "https://framerusercontent.com/assets/e2xSj9IcJ8dsIvELAZDx3LfWno.woff2"}]}], {supportsExplicitInterCodegen: true})